<template>
  <div class="" v-if="reviewList.length > 0">
    <div
      class="flex justify-between mx-4 -mb-[2px] items-end"
      v-if="totalReviewPosition == 'top'"
    >
      <div class="sm:basis-1/2 flex justify-center items-end">
        <NuxtImg
          loading="lazy"
          alt="refer a friend illustration"
          src="/img/Refer-a-friend-illustration-2.svg"
          class="md:h-30 lg:h-40"
          width="290"
          height="160"
        />
      </div>
      <div class="flex sm:basis-1/2 mx-1 sm:mx-4 pl-1 sm:pl-8 items-center self-end">
        <div class="font-semibold text-[72px] lg:text-[101px] hidden md:block">
          {{ totalReview.average_review }}
        </div>
        <div class="flex flex-col mx-4 mb-2 md:mb-0">
          <span class="font-bold text-blog-22 self-center">{{ getRatingScoreLabel(totalReview.average_review) }}</span>
          <CommonStarsRating
            class="text-[19px] mt-2 md:text-lg"
            :value="totalReview.average_review"
          />
          <span class="text-lg self-center">
            {{ totalReview.total_reviews }}
            {{ $t("reviewBlock.reviews") }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="bg-primary-100 grid grid-cols-1 md:grid-cols-2 md:px-12 pb-12 md:py-0 md:rounded-lg md:space-x-6 md:relative"
    >
      <div
        class="mx-12 md:mx-4 lg:mx-12 md:py-12 py-6 flex flex-col justify-center items-center md:items-start"
      >
        <div
          class="flex items-center md:justify-start justify-center"
          v-if="totalReviewPosition == 'center'"
        >
          <div class="text-white text-[53px] font-semibold">
            {{ totalReview.average_review }}
          </div>
          <div class="flex flex-col ml-3">
            <CommonStarsRating
              class="text-[21px] mt-2 md:text-lg"
              :value="list.rating"
            />
            <p class="text-lg font-semibold text-white">
              {{ totalReview.total_reviews }} {{ $t("reviewBlock.reviews") }}
            </p>
          </div>
        </div>

        <div class="reviewTitle my-4 md:text-left text-center">
          {{ $t("reviewBlock.title") }}
        </div>
        <NuxtLinkLocale :locale="switchBlogLocale()" to="/reviews">
          <BaseButton
            :text="$t('common.btnReadReviews')"
            class="w-64 md:mb-2 justify-center"
          ></BaseButton>
        </NuxtLinkLocale>
      </div>
   <ClientOnly> 
      <div
        class="flex justify-self-center md:justify-center flex-col mt-4 md:mt-0 mx-4 md:right-4 md:top-[40px] md:w-[512px] max-w-[360px] max-w-full bg-chinese-white rounded md:relative p-6 md:p-8 divide-y-2 shadow-default"
      >
        <div
          class="flex flex-col px-2 py-[30px] items-center md:items-start space-y-2"
          v-for="(list, index) in reviewList"
          :key="index"
        >
          <CommonStarsRating
            class="text-[21px] mt-2 md:text-lg"
            :value="list.rating"
          />
          <p class="md:order-1 order-2 leading-7">{{ list.review }}</p>
          <span class="font-bold md:order-2 order-1">
            {{ list.customer_name }}, {{ list.location }}</span
          >
        </div>
      </div>
    </ClientOnly>
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
const props = defineProps({
  reviewList: Array,
  totalReview: Object,
  totalReviewPosition: String,
});
const route = useRoute();
const isAbout = route.path.includes("/about-us");
</script>
